.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  width: 100%;
}

.green-gradient {
  background-color: rgb(0, 230, 118);
  background-image: linear-gradient(
    105deg,
    rgb(20, 136, 146) 0%,
    rgb(0, 230, 118) 100%
  );
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.red-gradient {
  background-color: rgb(230, 35, 0);
  background-image: linear-gradient(
    105deg,
    rgb(187, 59, 138) 0%,
    rgb(246, 144, 113) 100%
  );
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.info-gradient {
  background-color: #04aff9;
  background-image: linear-gradient(
    93.12deg,
    #04f9ea 0%,
    #04aff9 51.29%,
    #262ee6 100%
  );
  background-size: 150%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  animation: gradient-animation 10s infinite alternate ease-in-out;
}

.results {
  letter-spacing: 8px;
}

.year {
  z-index: 2;
  background-color: rgb(230, 35, 0);
  background-image: linear-gradient(
    106.35deg,
    #dfffc6 0%,
    #237eb2 28.96%,
    #a139d3 54.46%,
    #f88341 82.9%,
    #f9f7cc 100%
  );
  background-size: 200%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  animation: gradient-animation 10s infinite alternate ease-in-out;

  &.shadow {
    user-select: none;
    position: absolute;
    top: 8px;
    z-index: 1;
    opacity: 0.4;
    filter: blur(12px);
  }
}

@keyframes gradient-animation {
  0% {
    background-position: top right;
  }
  100% {
    background-position: top left;
  }
}
