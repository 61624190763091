.color-check-label {
  width: 48px;
  height: 48px;
  position: relative;
  display: inline-block;
  cursor: pointer;

  & input {
    position: absolute;
    opacity: 0;
  }

  &:hover .marker {
    transform: scale(1.1);
  }

  &:active .marker {
    transform: scale(1);
  }

  & .marker {
    position: absolute;
    transform: scale(1);
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    border-radius: 50%;
    transition: 0.2s ease-in-out;
    will-change: border-radius, transform;

    &.checked {
      border-radius: 6px;
      transform: rotate(45deg) scale(1.1);
    }
  }
}
